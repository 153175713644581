<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-3 form-group">
                    <input type="hidden" name="row_id" value="" />
                    <label class="control-label">Bulan</label>
                    <datepicker
                      placeholder="Bulan"
                      class="datep"
                      v-model="form.f_bulan"
                      :options="{format:'yyyy-mm', startView: 'months', minViewMode: 'months', autoclose: true}" 
                      :autoclose=true
                      required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Kelas</label>
                                <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>

        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser">
                <thead>
                    <tr>
                        <th style="width:70px;">NAMA SISWA</th>
                        <th style="width:60px;">KELAS</th>
                        <th v-for="n in 31" style="padding: 10px;">{{n}}</th>
                        <th>S</th>
                        <th>I</th>
                        <th>A</th>
                        <th><img src="https://img.icons8.com/metro/16/000000/checkmark.png"/></th>
                        <th><img src="https://img.icons8.com/metro/16/000000/multiply.png"/></th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        
        <div class="p-3"></div>
        
        <table class="table" style="width: 40%;">
            <tr>
                <td>Libur</td>
                <td><i class="fas fa-minus"></i></td>
            </tr>
            <tr>
                <td>Masuk</td>
                <td><i class="fas fa-check"></i></td>
            </tr>
            <tr>
                <td>Tidak Masuk</td>
                <td><i class="fas fa-times"></i></td>
            </tr>
        </table>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";
window.JSZip = require('jszip')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'RekapAbsensiSiswa',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            optKelas: [],
            roles: '',
            form: {
                name: '',
                f_bulan: '',
                kelas_id: ''
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)

        authFetch('/akademik/jadwal_tentor/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        search: function() {
            let self = this;
            // let bulan = moment().format("YYYY-MM");
            // console.log("bulan", bulan);
            this.table.api().ajax.reload();
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        var date = moment().format("YYYY-MM-DD");
        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
            ajax: "/administrasi/rekap_absensi_siswa",
            serverSide: false,
            scrollX: true,
            // columns: [
            //     { data: "name" },
            //     { data: "no_hp" },
            // ],
            filter:false,
            sort: false,
            rowCallback: function(row, data) {},
            paramData: function ( d ) {
                d.month = self.form.f_bulan;
                d.kelas_id = self.form.kelas_id;
            },
            dom: "<'row'<'col-sm-12 col-md-6 mb-2'B>><'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'row'<'col-sm-12'tr>><'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            buttons: [
                {
                    extend: 'excel',
                    text: 'XLSX',
                    className: 'btn btn-success',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37],
                    },
                    title: function(){
                        return 'Rekap Absensi Siswa';
                    },
                    filename: function(){
                        return 'Rekap Absensi Siswa - ' + date;
                    },
                    exportOptions: {
                        format: {
                            body: function ( data, row, column, node ) {
                                if(column >= 1 && data)
                                {
                                  data = data.replaceAll('<i class=\"fas fa-check\"></i>','v');
                                  data = data.replace('<i class=\"fas fa-check\"></i>', 'v');
                                  data = data.replaceAll('<i class=\"fas fa-times\"></i>','x');
                                  data = data.replace('<i class=\"fas fa-times\"></i>', 'x');
                                  return data;
                                }
                                else return data;
                            }
                        }
                    }
                },
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn btn-primary',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Rekap Absensi Siswa';
                    },
                    filename: function(){
                        return 'Rekap Absensi Siswa - ' + date;
                    },
                    exportOptions: {
                        format: {
                            body: function ( data, row, column, node ) {
                                if(column >= 1 && data)
                                {
                                  data = data.replaceAll('<i class=\"fas fa-check\"></i>','&#10003;');
                                  data = data.replace('<i class=\"fas fa-check\"></i>', '&#10003;');
                                  data = data.replaceAll('<i class=\"fas fa-times\"></i>','x');
                                  data = data.replace('<i class=\"fas fa-times\"></i>', 'x');
                                  return data;
                                }
                                else return data;
                            }
                        }
                    }
                },
                {
                    extend: 'pdf',
                    text: 'PDF',
                    className: 'btn btn-info',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37],
                    },
                    orientation: 'landscape',
                    title: function(){
                        return 'Rekap Absensi Siswa';
                    },
                    filename: function(){
                        return 'Rekap Absensi Siswa - ' + date;
                    },
                    exportOptions: {
                        format: {
                            body: function ( data, row, column, node ) {
                                if(column >= 1 && data)
                                {
                                  data = data.replaceAll('<i class=\"fas fa-check\"></i>','v');
                                  data = data.replace('<i class=\"fas fa-check\"></i>', 'v');
                                  data = data.replaceAll('<i class=\"fas fa-times\"></i>','x');
                                  data = data.replace('<i class=\"fas fa-times\"></i>', 'x');
                                  return data;
                                }
                                else return data;
                            }
                        }
                    }
                },
                {
                    extend: 'csvHtml5',
                    text: 'CSV',
                    className: 'btn bg-gray-dark',
                    exportOptions: {
                        columns:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37],
                    },
                    title: function(){
                        return 'Rekap Absensi Siswa';
                    },
                    filename: function(){
                        return 'Rekap Absensi Siswa - ' + date;
                    },
                    exportOptions: {
                        format: {
                            body: function ( data, row, column, node ) {
                                if(column >= 1 && data)
                                {
                                  data = data.replaceAll('<i class=\"fas fa-check\"></i>','v');
                                  data = data.replace('<i class=\"fas fa-check\"></i>', 'v');
                                  data = data.replaceAll('<i class=\"fas fa-times\"></i>','x');
                                  data = data.replace('<i class=\"fas fa-times\"></i>', 'x');
                                  return data;
                                }
                                else return data;
                            }
                        }
                    }
                },
            ],
        });
    }
}
</script>